body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.root {
  background-color: #fafafa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.link {
  color: #fff;
  text-decoration: none;
  font-weight: 800;
  margin-left: 10px;
  margin-right: 10px;
}

a {
  color: #fff;
  text-decoration: none;
  font-weight: 800;
  margin-left: 10px;
  margin-right: 10px;
}

.mobileLink {
  color: #555;
  text-decoration: none;
  font-weight: 800;
  margin-left: 10px;
  margin-right: 10px;
}

.tableHeader {
  background-color: #b930e6;
  color: white;
  font-family: "Urbanist", "sans-serif";
}

.codeInput {
  background-color: #fafafa;
  color: #000;
  font-family: "Urbanist", "sans-serif";
  border: 0;
  width: 100%;
  padding: 8px
}

.codeInput:hover{
  background-color: #fafafa;
}

.tableHeader:last-child > .MuiDataGrid-columnSeparator {
  display: none;
}

.amountColumn{
  text-align: right;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus{
  outline: none !important; 
}


.MuiDataGrid-row .MuiOutlinedInput-root{
  outline: none !important;
}

.MuiDataGrid-row .MuiDataGrid-cell:focus-within{
  outline: none !important;
}

.MuiDataGrid-row .MuiOutlinedInput-notchedOutline{
  border-width: 0 !important;
}

.hoverPointer{
  font-size: 12px;
}

.hoverPointer .MuiSelect-select{
  font-size: 12px !important;
}

.MuiDataGrid-cellContent{
  overflow-wrap: break-word !important;
  overflow:scroll !important;
  text-overflow:initial !important;
  white-space: normal !important;
}